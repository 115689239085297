@import 'pages/variables.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 30px 0;
  padding: 0 16px;
  width: 100%;
}

.item {
  white-space: nowrap;
  margin-right: 16px;
  width: 100%;
  height: 47px;
  border-bottom: 1px solid rgb($colorGray);
  display: inline-flex;
  align-items: center;
}

.item:last-child {
  border-bottom: none;
}

.link,
.link:visited {
  color: rgb($colorBlue);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
}

.link:hover,
.link:visited:hover {
  color: rgb($colorHover);
}

.icon {
@svg-load arrow url('components/common/icons/right-arrow-thin.svg') {
  path {
    fill: rgb($colorBlue);
  }
}
  display: inline-block;
  margin-left: auto;
  width: 28px;
  height: 32px;
  background: svg-inline(arrow) no-repeat center;
  background-size: 9px 16px;
}

.link:hover .icon,
.link:visited:hover .icon {
@svg-load arrow url('components/common/icons/right-arrow-thin.svg') {
  path {
    fill: rgb($colorHover);
  }
}
display: inline-block;
margin-left: auto;
width: 28px;
height: 32px;
background: svg-inline(arrow) no-repeat center;
background-size: 9px 16px;
}

@media (min-width: $breakpointSmall) {
  .root {
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    width: auto;
    padding-left: 0;
  }

  .item {
    width: auto;
    height: auto;
    border-bottom: none;
  }

  .link,
  .link:visited {
    display: inline-flex;
    width: auto;
    color: rgb($colorWhite);
  }

  .link:hover,
  .link:visited:hover {
    color: rgb($colorHover);
  }

  .icon {
    display: none;
  }

  .link:hover .icon,
  .link:visited:hover .icon {
    display: none;
  }
}
