@import 'pages/variables.css';

.calenderFooterChildren {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5px;
}

.calenderSkipButton {
  order: 2;
  justify-self: self-start;
  margin-top: 8px;
}

@media (min-width: $breakpointSmall) {
  .calenderFooterChildren {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 12px;
  }

  .calenderSkipButton {
    height: 100%;
    display: flex;
    align-items: flex-end;
    order: 0;
  }
}
