@import 'pages/variables.css';

.wrapper {
  margin-top: -57px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr;
}

.blackout {
  padding: 24px 16px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr auto;
}

.content {
  width: 100%;
  max-width: 650px;
  align-self: center;
  justify-self: center;
  padding-top: 80px;
}

.title {
  margin: 0 auto 16px;
  text-align: center;
  max-width: 250px;
}

.searchbarWrapper {
  max-width: 100%;
  margin: 0 auto;
}

.mobileActiveSearch {
  position: fixed;
  z-index: 11;
  top: 56px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgb($colorWhite);
  padding: 5px 16px;
}

.lodging {
  justify-self: end;
  align-self: end;
  height: auto;
  text-align: right;
}

.lodging p+p {
  margin: 0;
}

.lodgingLink:hover p {
  color: rgb($colorHover);
}

@media (min-width: $breakpointSmall) {
  .wrapper {
    margin-top: -81px;
    height: calc(100% + 81px);
    min-height: 500px;
  }

  .searchbarWrapper {
    max-width: 545px;
    margin: 0 auto;
  }

  .blackout {
    padding: 24px 32px;
  }

  .title {
    margin-bottom: 32px;
    max-width: 100%;
  }
}

@media (min-height: $breakpointSmall) {
  .content {
    padding-top: 0;
  }
}
