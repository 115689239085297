@import 'pages/variables.css';

.mobileBackLink {
  margin-left: -10px;
}

@media (min-width: $breakpointMedium) {
  .mobileBackLink {
    display: none;
  }
}
